<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Slug </v-list-item-title>
            <v-list-item-subtitle>{{ item.slug }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Icon </v-list-item-title>
            <v-list-item-subtitle>{{ item.icon }} </v-list-item-subtitle>
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Display Sequence </v-list-item-title>
            <v-list-item-subtitle> {{ item.sort }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { FeatureService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  created() {
    this.getFeature();
  },
  methods: {
    async getFeature() {
      const response = await FeatureService.getById(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
  },
};
</script>
